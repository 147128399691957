export enum WEBAPP_EVENTS {
  // Bridge events
  BRIDGE_CONNECTED = 'webapp.bridge_connected',

  // Mainblock events
  MAINBLOCK_CONNECTED = 'webapp.mainblock_connected',
}

export interface WEBAPP_EVENTS_PAYLOAD {
  [WEBAPP_EVENTS.BRIDGE_CONNECTED]: Record<string, never>;
  [WEBAPP_EVENTS.MAINBLOCK_CONNECTED]: Record<string, never>;
}
